import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, Button, Alert } from 'react-bootstrap';
import _ from 'lodash';

import { ENGLISH_CODE, LANG_PARAM } from '../../../../utils/translationsConstants';
import { createISODateTime } from '../../../../utils/date';
import { onHideHandler, renderShowConfirmationModal, Checkbox, PublicAccessLink } from '../../../../components';
import { Field, Errors } from '../../../../components/Form';
import { useBeforeUnload } from '../../../../hooks/useBeforeUnload/useBeforeUnload';
import { trackEvent, EVENT_TYPES } from '../../../../api/analytics';
import { makeGet, makePost } from '../../../../api/ajax';

import { actions as workflowsActions, selectors as workflowsSelectors } from '../../../../redux/workflows';
import { actions as translationsActions, selectors as translationsSelectors } from '../../../../redux/translations';
import { actions as customerConfigurationsActions, selectors as customerConfigurationsSelectors } from '../../../../redux/customerConfigurations';
import { selectors as customerSelectors } from '../../../../redux/customers';

import { CustomersTagCombobox } from '../../../../containers';

const loadWorkflows = (dispatch) => {
  makeGet('workflows/currentCustomer/admin').subscribe(({ response }) => {
    dispatch(workflowsActions.loadWorkflowsSuccess(response));
  });
};

const loadLanguages = (dispatch) => {
  makeGet('languages').subscribe(({ response }) => {
    dispatch(translationsActions.getLanguagesSuccess(response));
  });
};

const loadCustomerConfiguration = (dispatch) => {
  makeGet('customerConfigurations/current').subscribe(({ response }) => {
    dispatch(customerConfigurationsActions.loadCurrentCustomerConfigurationSuccess(response));
  });
};

const PublicLinkGeneratorForm = ({ id, show, onHide }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [publicAccessLink, setPublicAccessLink] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [nonStandardVinWarning, setNonStandardVinWarning] = useState(false);
  const [nonStandardVinConfirmed, setNonStandardVinConfirmed] = useState(false);
  const [fictionalVin, setFictionalVin] = useState(false);
  const [requestRegistrationNo, setRequestRegistrationNo] = useState(false);
  const [sharedWith, setSharedWith] = useState([]);

  const workflows = useSelector(workflowsSelectors.selectWorkFlows);
  const currentCustomer = useSelector(customerSelectors.selectCurrentCustomer);
  const customerConfiguration = useSelector(customerConfigurationsSelectors.selectCustomerConfiguration);
  const languages = useSelector(translationsSelectors.selectLanguages);

  const customerEnabledLanguages = customerConfiguration
    ? languages.filter((lang) => customerConfiguration.enabledLanguages.includes(lang.id))
    : [];

  const dispatch = useDispatch();

  useBeforeUnload(hasUnsavedChanges);

  useEffect(() => {
    if (workflows) return;
    loadWorkflows(dispatch);
  }, [workflows, dispatch]);

  useEffect(() => {
    if (languages?.length > 0) return;
    loadLanguages(dispatch);
  }, [languages, dispatch]);

  useEffect(() => {
    if (customerConfiguration) return;
    loadCustomerConfiguration(dispatch);
  }, [customerConfiguration, dispatch]);

  const handleSubmit = ({
    vin, regNumber, externalId, lang, reportCopyEmail, workflowId, expiresAt, setHasUnsavedChanges,
    returnUrl, referenceLabel, referenceValue, oneTimeUse
  }, setStatus, setSubmitting) => {
    setStatus();

    const params = {
      vin,
      registrationNo: regNumber,
      externalId,
      reportCopyEmail,
      workflowId,
      returnUrl,
      fictionalVin,
      oneTimeUse,
      requestRegistrationNo
    };

    if (expiresAt.length > 0) {
      // NOTE: We convert local expiresAt date and time into customer specific TZ
      params.expiresAt = createISODateTime(expiresAt, currentCustomer.timeZoneOffset);
    }

    if (referenceLabel.length > 0 || referenceValue.length > 0) {
      params.reportReference = {
        label: referenceLabel,
        value: referenceValue
      };
    }

    if (sharedWith.length > 0) {
      params.sharedWithCustomerIds = sharedWith.map((i) => i.id);
    }

    setPublicAccessLink(null);
    setErrorMessage(null);
    setHasUnsavedChanges(false);
    setNonStandardVinWarning(false);
    setNonStandardVinConfirmed(false);

    makePost('accessUrls', params).subscribe(
      ({ response }) => {
        // Set the language parameter for API generated URL
        const publicAccessUrl = `${response.url}&${LANG_PARAM}=${lang}`;

        setPublicAccessLink(publicAccessUrl);
        setSubmitting(false);

        // Track event in Analytics
        const workflow = _.find(workflows, (w) => w.id === params.workflowId);

        const data = {
          language: lang,
          vin: params.vin,
          registrationNo: params.registrationNo,
          externalId: params.externalId,
          reportCopyEmail: params.reportCopyEmail,
          workflowName: workflow.name,
          workflowVehicleType: workflow.vehicleType,
          expiresAt: params.expiresAt,
          fictionalVin,
          referenceLabel,
          referenceValue,
          oneTimeUse,
          sharedWithCustomerIds: params.sharedWith
        };

        trackEvent(EVENT_TYPES.VERIFICATION_REQUEST_GENERATED, data);
      },
      (err) => {
        const { status, response: { error } } = err;

        switch (status) {
          case 400:
            setErrorMessage(error);
            break;
          default:
            setErrorMessage(null);
            break;
        }

        setSubmitting(false);
        setHasUnsavedChanges(false);
        setNonStandardVinWarning(false);
        setNonStandardVinConfirmed(false);
      },
    );
  };

  if (!workflows || customerEnabledLanguages?.length < 1) return null;

  const languageOptions = _.map(
    customerEnabledLanguages,
    (lang) => ({ name: lang.name, value: lang.code })
  );

  const defaultLanguage = languageOptions.find((s) => s.value === currentCustomer.defaultLanguage).value;

  const initialValues = {
    vin: '',
    regNumber: '',
    externalId: '',
    reportCopyEmail: '',
    workflowId: workflows && Object.values(workflows).filter((w) => w.smartScanGDPRAcceptanceEnabled)[0]?.id,
    lang: defaultLanguage || ENGLISH_CODE,
    expiresAt: '',
    returnUrl: '',
    referenceLabel: '',
    referenceValue: '',
    oneTimeUse: false
  };

  return (
    <>
      {renderShowConfirmationModal(showConfirmModal, setShowConfirmModal, onHide)}
      <Modal
        id={id}
        show={show}
        size="lg"
        backdrop="static"
        centered
        onHide={() => onHideHandler(hasUnsavedChanges, onHide, setShowConfirmModal)}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            vin: Yup.string().min(5, 'Minimum length 5 characters').max(50, 'Maximum length 50 characters'),
            reportCopyEmail: Yup.string().email('Please enter a correct email'),
            externalId: Yup.string().max(50, 'Maximum length 50 characters.'),
            regNumber: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Numbers and letters only'),
            returnUrl: Yup.string()
              .min(10, 'Minimum length 10 characters')
              .matches(/^https?:/, 'Must start with https://'),
            referenceLabel: Yup.string().min(2, 'Minimum length 2 characters').max(20, 'Maximum length 20 characters'),
            referenceValue: Yup.string().min(2, 'Minimum length 2 characters').max(20, 'Maximum length 20 characters'),
          })}
          onSubmit={({
            vin, regNumber, externalId, lang, reportCopyEmail, workflowId, expiresAt, returnUrl, referenceLabel, referenceValue,
            oneTimeUse
          }, { setStatus, setSubmitting }) =>
            handleSubmit(
              {
                vin,
                regNumber,
                externalId,
                lang,
                reportCopyEmail,
                workflowId,
                expiresAt,
                setHasUnsavedChanges,
                returnUrl,
                referenceLabel,
                referenceValue,
                oneTimeUse
              },
              setStatus,
              setSubmitting,
            )}
          validateOnChange={false}
        >
          {({ values, errors, status, touched, isSubmitting, handleChange, setFieldValue }) => (
            <Form
              onChange={({ target }) => {
                if (!hasUnsavedChanges && target.value !== initialValues[target.name]) {
                  setHasUnsavedChanges(true);
                }
              }}
              onBlur={({ target }) => {
                if (target.name === 'vin') {
                  if (target.value.length > 4 && target.value.length < 51 && target.value.length !== 17) {
                    setNonStandardVinWarning(true);
                  } else {
                    setNonStandardVinWarning(false);
                    setNonStandardVinConfirmed(false);
                  }
                }
              }}
            >
              <Modal.Header closeButton>
                <h6>New vehicle request</h6>
              </Modal.Header>
              <Modal.Body>
                {
                  errorMessage && (
                    <Alert variant="danger">{errorMessage}</Alert>
                  )
                }
                <div className="row" style={{ marginBottom: '0.313rem' }}>
                  <div className="col-sm-4">
                    <Field
                      name="vin"
                      id="vin"
                      label="VIN code"
                      placeholder="Enter VIN"
                      type="text"
                      value={values.vin}
                      errors={errors.vin}
                      touched={touched.vin}
                      disabled={fictionalVin}
                    />
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="workflowId" className="mb-0 mt-1">
                        Workflow *
                      </label>
                      <select className="form-control" value={values.workflowId} onChange={handleChange} name="workflowId" id="workflowId">
                        {_.map(_.filter(workflows, (w) => w.deletedAt === null && w.smartScanGDPRAcceptanceEnabled), (workflow, index) => (
                          <option key={index} value={workflow.id}>
                            {workflow.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <Field
                      name="lang"
                      id="lang"
                      label="Language *"
                      type="select"
                      value={values.lang}
                      touched={touched.lang}
                      options={languageOptions}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 fictional-vin">
                    <Checkbox
                      id="fictionalVin"
                      label="Use fictional VIN"
                      name="fictionalVin"
                      checked={fictionalVin}
                      onClickHandler={() => {
                        setFictionalVin(!fictionalVin);
                        setNonStandardVinWarning(false);
                        setNonStandardVinConfirmed(false);
                        setFieldValue('vin', '');
                      }}
                      tooltip={(
                        <span>Use this feature only on edge cases, when you are not aware of the VIN code of the vehicle that is being captured.
                          <br />
                          <b>NB! It's important to turn off VIN validations or use setting “hidden”!</b>
                        </span>
                      )}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginBottom: '0.313rem' }}>
                  <div className="col-sm-4">
                    <Field
                      name="regNumber"
                      id="regNumber"
                      label="License plate"
                      placeholder="Enter license plate"
                      type="text"
                      value={values.regNumber}
                      errors={errors.regNumber}
                      touched={touched.regNumber}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Field
                      name="reportCopyEmail"
                      id="reportCopyEmail"
                      label="Report e-mail CC"
                      placeholder="Enter report e-mail CC"
                      type="text"
                      value={values.reportCopyEmail}
                      errors={errors.reportCopyEmail}
                      touched={touched.reportCopyEmail}
                    />
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="expiresAt" className="mb-0 mt-1">
                        Link expires after
                      </label>
                      <input
                        name="expiresAt"
                        id="expiresAt"
                        className="form-control"
                        type="datetime-local"
                        value={values.expiresAt}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <Checkbox
                      id="requestRegistrationNo"
                      label="Request license plate no"
                      name="requestRegistrationNo"
                      checked={requestRegistrationNo}
                      onClickHandler={() => setRequestRegistrationNo(!requestRegistrationNo)}
                      tooltip={(
                        <span>
                          Use this feature when you want to request license plate no of the vehicle by the user who captures the vehicle.
                        </span>
                      )}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginBottom: '1rem' }}>
                  <div className="col-sm-4">
                    <Field
                      id="returnUrl"
                      name="returnUrl"
                      placeholder="Enter return url"
                      label="Return url"
                      type="text"
                      value={values.returnUrl}
                      errors={errors.returnUrl}
                      touched={touched.returnUrl}
                      tooltip={'This field directs user to configured page after user has finished capturing all images. '
                        + 'It can be configured individually or in a workflow for every link.'}
                    />
                  </div>
                  <div className="col-sm-4">
                    <CustomersTagCombobox
                      label="Shared with"
                      initialSelection={[]}
                      setSelectedCustomers={(customers) => setSharedWith(customers)}
                    />
                  </div>
                  <div className="col-sm-4" style={{ paddingTop: '2rem' }}>
                    <Checkbox
                      id="oneTimeUse"
                      label="One time use"
                      name="oneTimeUse"
                      checked={values.oneTimeUse}
                      onClickHandler={() => {
                        setFieldValue('oneTimeUse', !values.oneTimeUse);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <h6>External references</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <Field
                      id="externalId"
                      name="externalId"
                      placeholder="Enter external ID"
                      label="External ID"
                      type="text"
                      value={values.externalId}
                      errors={errors.externalId}
                      touched={touched.externalId}
                      tooltip="This is a system ID in your CORE system that developers can use to connect contract or offer, to request
                        information about photo series."
                    />
                  </div>
                  <div className="col-sm-4">
                    <Field
                      id="referenceLabel"
                      name="referenceLabel"
                      label="Reference label on report"
                      placeholder="Contract no"
                      type="text"
                      value={values.referenceLabel}
                      errors={errors.referenceLabel}
                      touched={touched.referenceLabel}
                      tooltip={(
                        <span>
                          This field is rendered in a photo series report as the <b>label</b> of the connected object in your CORE system.
                          <br />E.g. "Contract no", "Offer no"
                        </span>
                      )}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Field
                      id="referenceValue"
                      name="referenceValue"
                      label="Reference value on report"
                      placeholder="PO11-4574-01"
                      type="text"
                      value={values.referenceValue}
                      errors={errors.referenceValue}
                      touched={touched.referenceValue}
                      tooltip={(
                        <span>
                          This field is rendered in a photo series report as the <b>value</b> of the connected object in your CORE system.
                          <br />E.g. a contract number "PO11-4574-01"
                        </span>
                      )}
                    />
                  </div>
                </div>
                <Errors status={status} />
                { publicAccessLink && <PublicAccessLink link={publicAccessLink} /> }
                {
                  nonStandardVinWarning && (
                    <Alert variant="warning">
                      <p>
                        <b>Confirm non-standard VIN!</b>
                      </p>
                      <p>
                        The standard length of a VIN code is 17 characters. You entered VIN that is {values.vin.length} characters long!
                      </p>
                      <Checkbox
                        id="confirmNonStandardVin"
                        label="Yes, this is not a mistake"
                        name="confirmNonStandardVin"
                        checked={nonStandardVinConfirmed}
                        onClickHandler={() => setNonStandardVinConfirmed(true)}
                      />
                    </Alert>
                  )
                }
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="button" shape="curvy" onClick={() => onHideHandler(hasUnsavedChanges, onHide, setShowConfirmModal)}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" shape="curvy" disabled={isSubmitting || (nonStandardVinWarning && !nonStandardVinConfirmed)}>
                  Generate link
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

PublicLinkGeneratorForm.propTypes = {
  id: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default PublicLinkGeneratorForm;
