import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MenuPage } from '../../layouts';
import { PageTitle, Badge, Text } from '../../components';
import { routes } from '../../routes/Routes';
import selectors from '../../redux/vehicles/selectors';
import { actions as vehiclesActions } from '../../redux/vehicles';
import { makeGet } from '../../api/ajax';
import { formatDate, formatDateTime } from '../../utils/date';
import { constants as fileConstants } from '../../redux/files';
import { composeApiUrl } from '../../api/composeApiUrl';
import { ReactComponent as IconDownload } from '../../assets/img/icn-download.svg';

import './sass/vehicles-exported.scss';

const VehiclesExported = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const exportedVehicles = useSelector(selectors.selectExportedVehicles);
  const isLoading = !exportedVehicles;
  const focusedRowRef = useRef(null);

  useEffect(() => {
    let interval = 0;
    if (!exportedVehicles) {
      makeGet('vehicles/export').subscribe(({ response }) => {
        dispatch(vehiclesActions.loadExportedVehiclesSuccess(response));
      });
    } else if (exportedVehicles.length > 0 && exportedVehicles.some((vehicle) => vehicle.status === 0)) {
      interval = setInterval(() => {
        makeGet('vehicles/export').subscribe(({ response }) => {
          dispatch(vehiclesActions.loadExportedVehiclesSuccess(response));
        });
      }, 7000);
    }
    return () => clearInterval(interval);
  }, [exportedVehicles, dispatch]);

  useEffect(() => {
    if (!isLoading && exportedVehicles && focusedRowRef.current) {
      const params = new URLSearchParams(location.search);
      const key = params.get('key');

      if (key) {
        focusedRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [isLoading, exportedVehicles, location.search]);

  const handleFileDownload = (vehicleFilterKey, fileEntityType) => {
    makeGet(`files/${vehicleFilterKey}/token?entityType=${fileEntityType}`).subscribe(
      ({ response }) => {
        window.location = composeApiUrl(`files/${vehicleFilterKey}/download?token=${response.token}`);
      },
      ({ response: { error } }) => console.error(error),
    );
  };

  return (
    <MenuPage currentRoute={routes.vehicles}>
      <PageTitle>
        <PageTitle.Left headingText="Exported vehicles" isLoading={isLoading}>
          {!isLoading && (
            <Badge className="ml-2">
              {isLoading ? 0 : exportedVehicles.length}
            </Badge>
          )}
        </PageTitle.Left>
        <PageTitle.Right />
      </PageTitle>

      <div className="row">
        <div className="col-sm-12">
          {!isLoading && (
            <div className="vehicles-exported-table">
              <div className="vehicles-exported-table-row">
                <div className="row">
                  <div className="col-sm-3 export-text">
                    <Text size={1} weight="bold">Completed date at range</Text>
                  </div>
                  <div className="col-sm-3 export-text">
                    <Text size={1} weight="bold">Export created at</Text>
                  </div>
                  <div className="col-sm-2 export-text">
                    <Text size={1} weight="bold">Status</Text>
                  </div>
                  <div className="col-sm-2 export-download-text">
                    <Text size={1} weight="bold">CSV</Text>
                  </div>
                  <div className="col-sm-2 export-download-text">
                    <Text size={1} weight="bold">Filter</Text>
                  </div>
                </div>
              </div>
              {exportedVehicles.map((vehicle) => {
                const isFocused = vehicle.vehicleFilterKey === new URLSearchParams(location.search).get('key');
                return (
                  <div
                    className={`vehicles-exported-table-row ${isFocused ? 'focused' : ''}`}
                    key={vehicle.vehicleFilterKey}
                    ref={isFocused ? focusedRowRef : null}
                  >
                    <div className="row">
                      <div className="col-sm-3 export-text">
                        <Text size={1}>{`${formatDate(vehicle.completedOnOrAfter)} - ${formatDate(vehicle.completedOnOrBefore)}`}</Text>
                      </div>
                      <div className="col-sm-3 export-text">
                        <Text size={1}>{formatDateTime(vehicle.exportCreatedAt)}</Text>
                      </div>
                      <div className="col-sm-2 export-text">
                        <Text size={1}>{vehicle.status === 1 ? 'Completed' : 'In progress'}</Text>
                      </div>
                      <div className="col-sm-2 export-download-text">
                        <button
                          className="btn btn-link d-flex align-items-center"
                          type="button"
                          onClick={() => handleFileDownload(vehicle.vehicleFilterKey, fileConstants.FILE_ENTITY_TYPES.VEHICLE_EXPORT_CSV)}
                          disabled={vehicle.status === 0}
                        >
                          <IconDownload className="mr-2" style={{ width: '1rem' }} />
                          <Text size={1} weight="bold" inline>Download</Text>
                        </button>
                      </div>
                      <div className="col-sm-2 export-download-text">
                        <button
                          className="btn btn-link d-flex align-items-center"
                          type="button"
                          onClick={() => handleFileDownload(vehicle.vehicleFilterKey, fileConstants.FILE_ENTITY_TYPES.VEHICLE_EXPORT_JSON)}
                          disabled={vehicle.status === 0}
                        >
                          <IconDownload className="mr-2" style={{ width: '1rem' }} />
                          <Text size={1} weight="bold" inline>Download</Text>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </MenuPage>
  );
};

export default VehiclesExported;
