import React from 'react';
import PropTypes from 'prop-types';

import { constants as imagesConstants } from '../../../../../../redux/images';
import { constants as workflowConstants } from '../../../../../../redux/workflows';
import { constants as sessionConstants } from '../../../../../../redux/session';

import { authenticationService } from '../../../../../../services';

import { Field } from '../../../../../../components/Form';
import { Card } from '../../../../../../components';

import ImageQualityValidationSettings from '../ImageQualityValidationSettings/ImageQualityValidationSettings';
import SmartScanCommentSettings from '../SmartScanCommentSettings/SmartScanCommentSettings';

import './sass/additional-image-type-modal.scss';

const { EDIT_WINDSHIELD_REPAIR_THRESHOLDS } = sessionConstants.PERMISSION_TYPES;

/* Requested image type option */
const REQUESTED_IMAGE_OPTION = {
  OFF: 0,
  MANDATORY: 1,
  SKIPPABLE: 2
};

const AdditionalImageTypeModal = ({ values, setFieldValue, workflow, errors, touched, imageTypeErrors }) => {
  const firstCloseUp = imagesConstants.IMAGE_SUB_TYPES.FIRST_CLOSE_UP;
  const secondCloseUp = imagesConstants.IMAGE_SUB_TYPES.SECOND_CLOSE_UP;
  const { manualDamageLabellingEnabled } = workflow;
  const { hasPermission } = authenticationService;

  const showDamageTypeMismatchError = imageTypeErrors.length > 0
    && imageTypeErrors.includes(imagesConstants.IMAGE_VERIFICATION_ERROR.DAMAGE_TYPE_MISMATCH);

  const showDamageNotFoundError = imageTypeErrors.length > 0
    && imageTypeErrors.includes(imagesConstants.IMAGE_VERIFICATION_ERROR.DAMAGE_NOT_FOUND);

  const handleRequestedImageTypeOptionChange = (closeupType, value) => {
    if (value !== REQUESTED_IMAGE_OPTION.OFF) {
      // Check if we have configuration for given sub type
      let subTypeConfiguration = values.imageSubTypes.find((configuration) => configuration.imageSubType === closeupType);
      if (!subTypeConfiguration) {
        // If not, add it
        subTypeConfiguration = {
          imageSubType: closeupType,
          instructions: [],
          optional: true
        };
      }

      subTypeConfiguration.optional = value === REQUESTED_IMAGE_OPTION.SKIPPABLE;

      setFieldValue('imageSubTypes', [
        ...values.imageSubTypes.filter((configuration) => configuration.imageSubType !== value),
        subTypeConfiguration
      ]);
    } else {
      setFieldValue(
        'imageSubTypes',
        closeupType === firstCloseUp
          ? []
          : values.imageSubTypes.filter((configuration) => configuration.imageSubType !== secondCloseUp)
      );
    }
  };

  const requestedImageTypeOption = (closeupType) => {
    const imageSubTypeConfiguration = values.imageSubTypes.find((configuration) => configuration.imageSubType === closeupType);
    if (!imageSubTypeConfiguration) return REQUESTED_IMAGE_OPTION.OFF;

    return imageSubTypeConfiguration.optional ? REQUESTED_IMAGE_OPTION.SKIPPABLE : REQUESTED_IMAGE_OPTION.MANDATORY;
  };

  return (
    <div className="additional-image-type-modal">
      {
        manualDamageLabellingEnabled && (
          <>
            <div className="row">
              <div className="col-sm-12">
                <Card>
                  <Card.Header>Requested images</Card.Header>
                  <Card.Body>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="asset" className="mb-0">
                            Closeup image 1
                          </label>
                          <select
                            className="form-control"
                            value={requestedImageTypeOption(firstCloseUp)}
                            onChange={(e) => {
                              handleRequestedImageTypeOptionChange(firstCloseUp, Number(e.target.value));
                            }}
                            name="asset"
                            id="asset"
                          >
                            <option value={REQUESTED_IMAGE_OPTION.OFF}>Off</option>
                            <option value={REQUESTED_IMAGE_OPTION.SKIPPABLE}>Skippable</option>
                            <option value={REQUESTED_IMAGE_OPTION.MANDATORY}>Mandatory</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="asset" className="mb-0">
                            Closeup image 2
                          </label>
                          <select
                            className="form-control"
                            value={requestedImageTypeOption(secondCloseUp)}
                            onChange={(e) => {
                              handleRequestedImageTypeOptionChange(secondCloseUp, Number(e.target.value));
                            }}
                            name="asset"
                            id="asset"
                            disabled={
                              !values.imageSubTypes.some((configuration) => configuration.imageSubType === firstCloseUp)
                            }
                          >
                            <option value={REQUESTED_IMAGE_OPTION.OFF}>Off</option>
                            <option value={REQUESTED_IMAGE_OPTION.SKIPPABLE}>Skippable</option>
                            <option value={REQUESTED_IMAGE_OPTION.MANDATORY}>Mandatory</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <Card>
                  <Card.Header>Validations</Card.Header>
                  <Card.Body>
                    <div className="row mt-2">
                      <div className="col-sm-12 sub-section">
                        <span className="sub-section-header">IMAGE QUALITY</span>
                      </div>
                    </div>
                    {
                      showDamageTypeMismatchError && (
                        <div className="row">
                          <div className="col-sm-6">
                            <ImageQualityValidationSettings
                              error={imagesConstants.IMAGE_VERIFICATION_ERROR.DAMAGE_TYPE_MISMATCH}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          {
                            hasPermission(EDIT_WINDSHIELD_REPAIR_THRESHOLDS) && (
                              <div className="col-sm-6">
                                <Field
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="windshieldRepairDamagePredictionConfidenceThreshold"
                                  label="Damage confidence threshold"
                                  touched={touched.windshieldRepairDamagePredictionConfidenceThreshold}
                                  errors={errors.windshieldRepairDamagePredictionConfidenceThreshold}
                                  // eslint-disable-next-line max-len
                                  tooltip="Enter value between 0-100. If repair decision confidence below value then 'Damage type prediction below threshold' verification error will be added."
                                />
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                    {
                      showDamageNotFoundError && (
                        <div className="row">
                          <div className="col-sm-6">
                            <ImageQualityValidationSettings
                              error={imagesConstants.IMAGE_VERIFICATION_ERROR.DAMAGE_NOT_FOUND}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                      )
                    }
                  </Card.Body>
                </Card>
              </div>
            </div>
          </>
        )
      }
      <div className={manualDamageLabellingEnabled ? 'row mt-3' : 'row'}>
        <div className="col-12">
          <Card>
            <Card.Header>
              Other settings
            </Card.Header>
            <Card.Body>
              <div className="row mt-2">
                <div className="col-sm-12 sub-section">
                  <span className="sub-section-header">COMMENTS</span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 smart-scan-comment-settings">
                  <SmartScanCommentSettings values={values} setFieldValue={setFieldValue} />
                </div>
              </div>
              {
                manualDamageLabellingEnabled && (
                  <>
                    <div className="row">
                      <div className="col-sm-12">
                        <hr />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-12 sub-section">
                        <span className="sub-section-header">DAMAGE DETECTION</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="asset" className="mb-0">
                            Manual marking
                          </label>
                          <select
                            className="form-control"
                            value={values.manualDamageLabelling.damageMarkingLevel}
                            onChange={(e) => {
                              setFieldValue('manualDamageLabelling.damageMarkingLevel', Number(e.target.value));
                            }}
                            name="asset"
                            id="asset"
                          >
                            <option value={workflowConstants.DAMAGE_MARKING_LEVEL.HIDDEN}>Hidden</option>
                            <option value={workflowConstants.DAMAGE_MARKING_LEVEL.WARNING}>Warning</option>
                            <option value={workflowConstants.DAMAGE_MARKING_LEVEL.BLOCKER}>Blocker</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

AdditionalImageTypeModal.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  imageTypeErrors: PropTypes.array.isRequired
};

export default AdditionalImageTypeModal;
