import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '../../assets/img/icn-close.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/img/icn-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/img/icn-chevron-right.svg';

import './sass/sidebar.scss';

import { Button } from '..';

const Section = ({ className, children }) => (
  <div className={`sidebar-section ${className || ''}`}>
    {children}
  </div>
);

const SectionHeader = ({ text, additional, children }) => (
  <div className="sidebar-section__header">
    <span className="sidebar-section__header-prefix">
      {children}
    </span>
    <span className="sidebar-section__header-text">
      {text}
    </span>
    { additional }
  </div>
);

const SectionBody = ({ children, className }) => (
  <div className={`sidebar-section__body ${className || ''}`}>
    {children}
  </div>
);

const Sidebar = ({ title, subTitle, onClose, children, hidden, onToggleHideClick, additionalActions }) => (
  <div className={`sidebar p-4 ${hidden ? 'hidden' : ''}`}>
    <div className={`d-flex ${hidden ? 'flex-column-reverse' : 'flex-row justify-content-end'}`}>
      {
        !hidden && additionalActions
      }
      <div className={`${hidden ? 'w-100 text-center' : 'mr-3'}`}>
        <Button
          className={`btn icon ${hidden ? 'mt-3' : ''}`}
          variation="light-blue"
          type="button"
          testId="hideSidebarBtn"
          onClick={onToggleHideClick}
        >
          {hidden ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </Button>
      </div>
      <div className={`${hidden ? 'w-100 text-center' : 'text-right'}`}>
        <Button
          type="button"
          className="btn icon"
          variation="light-blue"
          testId="closeSidebarBtn"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </div>
    </div>
    <div className={`contents ${hidden ? 'hidden' : ''}`}>
      <div className="mb-4">
        <h5 className="mt-3 mb-0">{title}</h5>
        { subTitle && (<span className="subTitle">{subTitle}</span>) }
      </div>
      {children}
    </div>
  </div>
);

Section.Header = SectionHeader;
Section.Body = SectionBody;
Sidebar.Section = Section;

SectionHeader.propTypes = {
  text: PropTypes.node.isRequired,
  children: PropTypes.node,
  additional: PropTypes.node
};

SectionBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onToggleHideClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
  additionalActions: PropTypes.node
};

export default Sidebar;
