import { constants as workflowsConstants } from '../workflows';

const NAME = 'images';

/**
 * @VEHICLE_ERROR_TYPES
 * When we have other vehicle types e.g: farm mechineries, all
 * we need to do is just to add its vehicleType and TYPE_ERRORS
 */
const VEHICLE_ERROR_TYPES = {
  [workflowsConstants.VEHICLE_TYPES.CAR]: 'CAR_TYPE_ERRORS',
  [workflowsConstants.VEHICLE_TYPES.MOTORCYCLE]: 'MOTORCYCLE_TYPE_ERRORS',
  [workflowsConstants.VEHICLE_TYPES.TRUCK]: 'TRUCK_TYPE_ERRORS',
  [workflowsConstants.VEHICLE_TYPES.BUS]: 'BUS_TYPE_ERRORS',
  [workflowsConstants.VEHICLE_TYPES.TRAILER]: 'TRAILER_TYPE_ERRORS'
};

const IMAGE_TYPES = {
  EXTERIOR: 1,
  INTERIOR: 2,
  VIN: 3,
  WINDSHIELD: 4,
  ADDITIONAL: 5,
  ODOMETER: 6,
  KEYS: 7,
  DATA_STICKER: 8,
  WHEELS: 9,
  CUSTOM: 10
};

const IMAGE_SUB_TYPES = {
  FRONT: 1,
  FRONT_LEFT: 2,
  LEFT: 3,
  BACK_LEFT: 4,
  BACK: 5,
  BACK_RIGHT: 6,
  RIGHT: 7,
  FRONT_RIGHT: 8,
  DASHBOARD: 10,
  TRUNK: 11,
  FRONT_LEFT_SEAT: 12,
  BACK_LEFT_SEAT: 13,
  FRONT_RIGHT_SEAT: 14,
  BACK_RIGHT_SEAT: 15,
  FROM_OUTSIDE: 20,
  FROM_INSIDE: 21,
  FACTORY_LABEL: 22,
  FIRST_VIN: 30,
  INSPECTION_STICKER: 31,
  SECOND_VIN: 32,
  FIRST_CLOSE_UP: 40,
  SECOND_CLOSE_UP: 41
};

const CAPTURE_NAMES = {
  [IMAGE_TYPES.EXTERIOR]: '360',
  [IMAGE_TYPES.INTERIOR]: 'Interior',
  [IMAGE_TYPES.VIN]: 'VIN',
  [IMAGE_TYPES.WINDSHIELD]: 'Windshield',
  [IMAGE_TYPES.ADDITIONAL]: 'Additionals',
  [IMAGE_TYPES.ODOMETER]: 'Odometer',
  [IMAGE_TYPES.KEYS]: 'Keys',
  [IMAGE_TYPES.DATA_STICKER]: 'Data sticker',
  [IMAGE_TYPES.WHEELS]: 'Wheels',
  [IMAGE_TYPES.CUSTOM]: 'Custom'
};

const SUBTYPE_NAMES = {
  [IMAGE_SUB_TYPES.FRONT]: 'front',
  [IMAGE_SUB_TYPES.FRONT_LEFT]: 'front left',
  [IMAGE_SUB_TYPES.LEFT]: 'left',
  [IMAGE_SUB_TYPES.BACK_LEFT]: 'back left',
  [IMAGE_SUB_TYPES.BACK]: 'back',
  [IMAGE_SUB_TYPES.BACK_RIGHT]: 'back right',
  [IMAGE_SUB_TYPES.RIGHT]: 'right',
  [IMAGE_SUB_TYPES.FRONT_RIGHT]: 'front right',
  [IMAGE_SUB_TYPES.DASHBOARD]: 'dashboard',
  [IMAGE_SUB_TYPES.TRUNK]: 'trunk',
  [IMAGE_SUB_TYPES.FRONT_LEFT_SEAT]: 'front left seat',
  [IMAGE_SUB_TYPES.BACK_LEFT_SEAT]: 'back left seat',
  [IMAGE_SUB_TYPES.FRONT_RIGHT_SEAT]: 'front right seat',
  [IMAGE_SUB_TYPES.BACK_RIGHT_SEAT]: 'back right seat',
  [IMAGE_SUB_TYPES.FROM_OUTSIDE]: 'from outside',
  [IMAGE_SUB_TYPES.FROM_INSIDE]: 'from inside',
  [IMAGE_SUB_TYPES.FIRST_VIN]: 'on vehicle',
  [IMAGE_SUB_TYPES.INSPECTION_STICKER]: 'inspection sticker',
  [IMAGE_SUB_TYPES.SECOND_VIN]: 'second image on vehicle',
  [IMAGE_SUB_TYPES.FIRST_CLOSE_UP]: 'first close up',
  [IMAGE_SUB_TYPES.SECOND_CLOSE_UP]: 'second close up',
  [IMAGE_SUB_TYPES.FACTORY_LABEL]: 'factory label'
};

const IMAGE_VERIFICATION_ERROR = {
  BLURRY_IMAGE: 1,
  VEHICLE_NOT_IN_FRAME: 2,
  NO_REGISTRATION_NUMBER: 3,
  REGISTRATION_NUMBER_MISMATCH: 4,
  NO_VIN: 6,
  VIN_MISMATCH: 7,
  VEHICLE_TOO_FAR: 9,
  VEHICLE_NOT_IN_THE_CENTER: 10,
  TIME_LIMIT_EXCEEDED: 11,
  WINDSHIELD_NOT_IN_FRAME: 12,
  WRONG_POSE: 13,
  DIRTY: 14,
  SNOWY: 15,
  GLARED: 16,
  RAINY: 17,
  INVALID_COUNTRY: 18,
  OVEREXPOSED: 19,
  WINDSHIELD_NOT_IN_THE_CENTER: 20,
  DAMAGE_TYPE_MISMATCH: 21,
  WINDSHIELD_REPAIR_CONFIDENCE_BELOW_THRESHOLD: 22,
  DAMAGE_TYPE_PREDICTION_BELOW_THRESHOLD: 23,
  DAMAGE_NOT_FOUND: 24
};

/**
 * @IMAGE_VALIDATION_NAME
 * Used to configure validation errors in Workflow settings
 * */
const IMAGE_VALIDATION_NAME = {
  [IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE]: 'Image sharpness validation',
  [IMAGE_VERIFICATION_ERROR.VEHICLE_NOT_IN_FRAME]: 'Vehicle in frame validation',
  [IMAGE_VERIFICATION_ERROR.NO_REGISTRATION_NUMBER]: 'Missing registration number validation',
  [IMAGE_VERIFICATION_ERROR.REGISTRATION_NUMBER_MISMATCH]: 'Registration number mismatch validation',
  [IMAGE_VERIFICATION_ERROR.COLOR_MISMATCH]: 'Color mismatch validation',
  [IMAGE_VERIFICATION_ERROR.NO_VIN]: 'Missing VIN validation',
  [IMAGE_VERIFICATION_ERROR.VIN_MISMATCH]: 'VIN mismatch validation',
  [IMAGE_VERIFICATION_ERROR.VEHICLE_TOO_FAR]: 'Vehicle too far',
  [IMAGE_VERIFICATION_ERROR.VEHICLE_NOT_IN_THE_CENTER]: 'Vehicle not in the center',
  [IMAGE_VERIFICATION_ERROR.WINDSHIELD_NOT_IN_FRAME]: 'Windshield in frame validation',
  [IMAGE_VERIFICATION_ERROR.WINDSHIELD_NOT_IN_THE_CENTER]: 'Windshield not in the center',
  [IMAGE_VERIFICATION_ERROR.WRONG_POSE]: 'Correct angle validation',
  [IMAGE_VERIFICATION_ERROR.DIRTY]: 'Dirt validation',
  [IMAGE_VERIFICATION_ERROR.SNOWY]: 'Snow validation',
  [IMAGE_VERIFICATION_ERROR.GLARED]: 'Glared validation',
  [IMAGE_VERIFICATION_ERROR.RAINY]: 'Rain validation',
  [IMAGE_VERIFICATION_ERROR.OVEREXPOSED]: 'Overexposed validation',
  [IMAGE_VERIFICATION_ERROR.DAMAGE_TYPE_MISMATCH]: 'Damage type mismatch validation',
  [IMAGE_VERIFICATION_ERROR.DAMAGE_NOT_FOUND]: 'Damage not found validation'
};

/**
 * @IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT
 * Used to display history of verified cars with error e.g in PhotoSeries view
 * */
const IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT = {
  [IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE]: 'Blurry image',
  [IMAGE_VERIFICATION_ERROR.VEHICLE_NOT_IN_FRAME]: 'Vehicle is not found',
  [IMAGE_VERIFICATION_ERROR.NO_REGISTRATION_NUMBER]: 'License plate is not found',
  [IMAGE_VERIFICATION_ERROR.REGISTRATION_NUMBER_MISMATCH]: 'License plate number mismatch',
  [IMAGE_VERIFICATION_ERROR.NO_VIN]: 'VIN code not found',
  [IMAGE_VERIFICATION_ERROR.VIN_MISMATCH]: 'VIN code mismatch',
  [IMAGE_VERIFICATION_ERROR.VEHICLE_TOO_FAR]: 'Vehicle too far',
  [IMAGE_VERIFICATION_ERROR.VEHICLE_NOT_IN_THE_CENTER]: 'Vehicle too close to image edge',
  [IMAGE_VERIFICATION_ERROR.TIME_LIMIT_EXCEEDED]: 'Time limit exceeded',
  [IMAGE_VERIFICATION_ERROR.WRONG_POSE]: 'Vehicle captured in the wrong pose',
  [IMAGE_VERIFICATION_ERROR.WINDSHIELD_NOT_IN_FRAME]: 'Windshield is not found',
  [IMAGE_VERIFICATION_ERROR.DIRTY]: 'Dirty',
  [IMAGE_VERIFICATION_ERROR.SNOWY]: 'Snowy',
  [IMAGE_VERIFICATION_ERROR.GLARED]: 'Glared',
  [IMAGE_VERIFICATION_ERROR.RAINY]: 'Rainy',
  [IMAGE_VERIFICATION_ERROR.INVALID_COUNTRY]: 'Invalid country',
  [IMAGE_VERIFICATION_ERROR.OVEREXPOSED]: 'Overexposed',
  [IMAGE_VERIFICATION_ERROR.WINDSHIELD_NOT_IN_THE_CENTER]: 'Windshield too close to image edge',
  [IMAGE_VERIFICATION_ERROR.DAMAGE_TYPE_MISMATCH]: 'Damage type mismatch',
  [IMAGE_VERIFICATION_ERROR.WINDSHIELD_REPAIR_CONFIDENCE_BELOW_THRESHOLD]: 'Repair confidence below threshold',
  [IMAGE_VERIFICATION_ERROR.DAMAGE_TYPE_PREDICTION_BELOW_THRESHOLD]: 'Damage type prediction below threshold',
  [IMAGE_VERIFICATION_ERROR.DAMAGE_NOT_FOUND]: 'Damage not found'
};

const CAR_TYPE_ERRORS = {
  [IMAGE_TYPES.EXTERIOR]: [
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE,
    IMAGE_VERIFICATION_ERROR.NO_REGISTRATION_NUMBER,
    IMAGE_VERIFICATION_ERROR.REGISTRATION_NUMBER_MISMATCH,
    IMAGE_VERIFICATION_ERROR.VEHICLE_NOT_IN_FRAME,
    IMAGE_VERIFICATION_ERROR.VEHICLE_TOO_FAR,
    IMAGE_VERIFICATION_ERROR.VEHICLE_NOT_IN_THE_CENTER,
    IMAGE_VERIFICATION_ERROR.WRONG_POSE
  ],
  [IMAGE_TYPES.INTERIOR]: [],
  [IMAGE_TYPES.VIN]: [
    IMAGE_VERIFICATION_ERROR.NO_VIN,
    IMAGE_VERIFICATION_ERROR.VIN_MISMATCH,
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE
  ],
  [IMAGE_TYPES.WINDSHIELD]: [
    IMAGE_VERIFICATION_ERROR.WINDSHIELD_NOT_IN_FRAME,
    IMAGE_VERIFICATION_ERROR.WINDSHIELD_NOT_IN_THE_CENTER,
    IMAGE_VERIFICATION_ERROR.WRONG_POSE
  ],
  [IMAGE_TYPES.ADDITIONAL]: [
    IMAGE_VERIFICATION_ERROR.DAMAGE_TYPE_MISMATCH,
    IMAGE_VERIFICATION_ERROR.DAMAGE_NOT_FOUND
  ],
  [IMAGE_TYPES.ODOMETER]: [
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE,
  ],
};

const MOTORCYCLE_TYPE_ERRORS = {
  [IMAGE_TYPES.EXTERIOR]: [
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE,
    IMAGE_VERIFICATION_ERROR.WRONG_POSE
  ],
  [IMAGE_TYPES.VIN]: [
    IMAGE_VERIFICATION_ERROR.NO_VIN,
    IMAGE_VERIFICATION_ERROR.VIN_MISMATCH,
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE
  ],
  [IMAGE_TYPES.WINDSHIELD]: [],
  [IMAGE_TYPES.ADDITIONAL]: null,
  [IMAGE_TYPES.ODOMETER]: [
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE
  ],
};

const TRUCK_TYPE_ERRORS = {
  [IMAGE_TYPES.VIN]: [
    IMAGE_VERIFICATION_ERROR.NO_VIN,
    IMAGE_VERIFICATION_ERROR.VIN_MISMATCH,
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE
  ],
  [IMAGE_TYPES.EXTERIOR]: [
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE,
    IMAGE_VERIFICATION_ERROR.WRONG_POSE
  ],
  [IMAGE_TYPES.ODOMETER]: [
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE
  ]
};

const BUS_TYPE_ERRORS = {
  [IMAGE_TYPES.VIN]: [
    IMAGE_VERIFICATION_ERROR.NO_VIN,
    IMAGE_VERIFICATION_ERROR.VIN_MISMATCH,
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE
  ],
  [IMAGE_TYPES.EXTERIOR]: [
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE,
    IMAGE_VERIFICATION_ERROR.NO_REGISTRATION_NUMBER,
    IMAGE_VERIFICATION_ERROR.REGISTRATION_NUMBER_MISMATCH,
    IMAGE_VERIFICATION_ERROR.WRONG_POSE
  ],
  [IMAGE_TYPES.ODOMETER]: [
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE
  ]
};

const TRAILER_TYPE_ERRORS = {
  [IMAGE_TYPES.VIN]: [
    IMAGE_VERIFICATION_ERROR.NO_VIN,
    IMAGE_VERIFICATION_ERROR.VIN_MISMATCH,
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE
  ],
  [IMAGE_TYPES.EXTERIOR]: [
    IMAGE_VERIFICATION_ERROR.BLURRY_IMAGE,
    IMAGE_VERIFICATION_ERROR.NO_REGISTRATION_NUMBER,
    IMAGE_VERIFICATION_ERROR.REGISTRATION_NUMBER_MISMATCH,
    IMAGE_VERIFICATION_ERROR.WRONG_POSE
  ]
};

const IMAGE_VERIFICATION_ERROR_DEPENDENCIES = {
  [IMAGE_VERIFICATION_ERROR.VEHICLE_TOO_FAR]: IMAGE_VERIFICATION_ERROR.VEHICLE_NOT_IN_FRAME,
  [IMAGE_VERIFICATION_ERROR.VEHICLE_NOT_IN_THE_CENTER]: IMAGE_VERIFICATION_ERROR.VEHICLE_NOT_IN_FRAME,
};

const IMAGE_VERIFICATION_ERROR_TYPE = {
  BLOCKER: 1,
  WARNING: 2,
  HIDDEN: 3
};

const IMAGE_VERIFICATION_ERROR_TYPE_NAMES = {
  [IMAGE_VERIFICATION_ERROR_TYPE.BLOCKER]: 'Blocker',
  [IMAGE_VERIFICATION_ERROR_TYPE.WARNING]: 'Warning',
  [IMAGE_VERIFICATION_ERROR_TYPE.HIDDEN]: 'Hidden'
};

const IMAGE_TYPE_VISIBILITY_ERRORS = {
  [IMAGE_TYPES.EXTERIOR]: [
    IMAGE_VERIFICATION_ERROR.DIRTY,
    IMAGE_VERIFICATION_ERROR.SNOWY,
    IMAGE_VERIFICATION_ERROR.GLARED,
    IMAGE_VERIFICATION_ERROR.RAINY,
  ],
};

const INSTRUCTION_TYPE = {
  FIRST_WINDSHIELD: 0,
  SECOND_WINDSHIELD: 1,
  DOOR_JAMB: 5,
  UNDER_DRIVER_SEAT: 10,
  PASSENGER_DOOR_AND_SEAT: 15,
  ENGINE_ROOM: 20,
  PASSENGER_SIDE: 25
};

const INSTRUCTION_TYPE_NAMES = {
  [INSTRUCTION_TYPE.FIRST_WINDSHIELD]: 'Windshield',
  [INSTRUCTION_TYPE.SECOND_WINDSHIELD]: 'Windshield 2',
  [INSTRUCTION_TYPE.DOOR_JAMB]: 'Door jamb',
  [INSTRUCTION_TYPE.UNDER_DRIVER_SEAT]: 'Under driver seat',
  [INSTRUCTION_TYPE.PASSENGER_DOOR_AND_SEAT]: 'Passenger door and seat',
  [INSTRUCTION_TYPE.ENGINE_ROOM]: 'Engine room',
  [INSTRUCTION_TYPE.PASSENGER_SIDE]: 'Passenger side'
};

export default {
  NAME,
  IMAGE_TYPES,
  IMAGE_SUB_TYPES,
  CAPTURE_NAMES,
  IMAGE_VERIFICATION_ERROR,
  IMAGE_VALIDATION_NAME,
  CAR_TYPE_ERRORS,
  MOTORCYCLE_TYPE_ERRORS,
  TRUCK_TYPE_ERRORS,
  BUS_TYPE_ERRORS,
  TRAILER_TYPE_ERRORS,
  IMAGE_VERIFICATION_ERROR_DEPENDENCIES,
  IMAGE_VERIFICATION_ERROR_TYPE,
  IMAGE_VERIFICATION_ERROR_TYPE_NAMES,
  IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT,
  VEHICLE_ERROR_TYPES,
  SUBTYPE_NAMES,
  IMAGE_TYPE_VISIBILITY_ERRORS,
  INSTRUCTION_TYPE,
  INSTRUCTION_TYPE_NAMES
};
