const NAME = 'experimentalFeatures';

const EXPERIMENTAL_FEATURE_TYPE = {
  DAMAGE_TYPE_MISMATCH_VERIFICATION_ERROR: 0,
  WINDSHIELD_REPAIR_CONFIDENCE_BELOW_THRESHOLD_VERIFICATION_ERROR: 1,
  DAMAGE_TYPE_PREDICTION_BELOW_THRESHOLD_VERIFICATION_ERROR: 2,
  DAMAGE_NOT_FOUND_VERIFICATION_ERROR: 3
};

export default {
  NAME,
  EXPERIMENTAL_FEATURE_TYPE
};
