import { createSelector } from 'reselect';
import _ from 'lodash';

import constants from './constants';

const selectVehiclesState = (state) => state[constants.NAME];

const selectPagedResult = createSelector([selectVehiclesState], ({ pagedResult }) =>
  _.isEmpty(pagedResult)
    ? null
    : pagedResult,);

const getVehicleSearchItemKey = (_, vehicleId) => vehicleId;

const selectVehiclePhotoSeriesPagedResult = createSelector(
  selectVehiclesState,
  getVehicleSearchItemKey,
  ({ vehiclePhotoSeries }, vehicleSearchItemKey) => (vehiclePhotoSeries && vehiclePhotoSeries[vehicleSearchItemKey]),
);

const selectExportedVehicles = createSelector(
  selectVehiclesState,
  ({ exportedVehicles }) => exportedVehicles,
);

export default {
  selectPagedResult,
  selectVehiclePhotoSeriesPagedResult,
  selectExportedVehicles
};
