import React from 'react';
import PropTypes from 'prop-types';

import { constants as imagesConstants } from '../../../../../../redux/images';

// TODO:  New component for image validation settings
//        Currently draft implementation here and will be improved over time
const ImageQualityValidationSettings = ({ error, values, setFieldValue }) => {
  const offOption = 1000;

  const handleChange = (errorType) => {
    const { enabledVerificationErrors } = values;

    if (errorType === offOption) {
      enabledVerificationErrors[error].enabled = false;
    } else {
      enabledVerificationErrors[error].errorType = errorType;
      enabledVerificationErrors[error].enabled = true;
    }
    setFieldValue(values.enabledVerificationErrors, enabledVerificationErrors);
  };

  const value = values.enabledVerificationErrors[error].enabled
    ? values.enabledVerificationErrors[error].errorType
    : offOption;

  return (
    <div className="form-group">
      <label htmlFor="asset" className="mb-0">
        {imagesConstants.IMAGE_VALIDATION_NAME[error]}
      </label>
      <select
        className="form-control"
        value={value}
        onChange={(e) => handleChange(Number(e.target.value))}
        name="asset"
        id="asset"
      >
        <option value={imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE.BLOCKER}>
          {imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE_NAMES[imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE.BLOCKER]}
        </option>
        <option value={imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE.WARNING}>
          {imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE_NAMES[imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE.WARNING]}
        </option>
        <option value={imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE.HIDDEN}>
          {imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE_NAMES[imagesConstants.IMAGE_VERIFICATION_ERROR_TYPE.HIDDEN]}
        </option>
        <option value={offOption}>Off</option>
      </select>
    </div>
  );
};

ImageQualityValidationSettings.propTypes = {
  error: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default ImageQualityValidationSettings;
